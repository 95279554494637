<template>
    <div class="publi item">
        <div class="top">
            <div class="left">
                <div class="icon"></div>
                <div class="title"><span>Publicidade</span></div>
            </div>
        </div>
        <div class="content">
            <button @click="prev()">PREV</button>
            <button @click="next()">NEXT</button>
            <transition-group name="fade" enter-active-class="animate__animated animate__fadeIn" leave-active-class="animate__animated animate__fadeOut" tag="ul">
                <li v-for="(s, index) in slides" :key="index" v-show="this.currentIndex == index" class="boxSlide">
                    <div v-if="s.guia == 'true'">
						<a :href="s.url" target="_blank" rel="noopener noreferrer">
                            <div class="img" :style="{backgroundImage: 'url('+this.uploads+s.imagem+')'}"></div>
                            <div class="data">
                                <span>{{ s.title }}</span><br>
                                <span>{{ s.descricao }}</span>
                            </div>
                        </a>
                    </div>
					<div v-else>
                        <a :href="s.url" rel="noopener noreferrer">
                            <div class="img" :style="{backgroundImage: 'url('+s.imagem+')'}"></div>
                            <div class="data">
                                <span>{{ s.title }}</span><br>
                                <span>{{ s.descricao }}</span>
                            </div>
                        </a>
					</div>
                </li>
            </transition-group>
            <div class="bolls">
                <div class="boll" v-for="(n, index) in slides.length" :key="index" :class="{active: currentIndex == index}"><i class="fa-solid fa-circle"></i></div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import {base} from '../../../var.js'

export default {
    name: 'PubliItem',
    data() {
		return {
			slides: [],
			timer: null,
			currentIndex: 0
		}
	},
	methods: {
		async getSlides(){
			const req = await axios.get(base+'getItens/publi')
			this.slides = req.data
		},
		startSlide(){
			this.getSlides();
			this.next();
			setInterval(() => {
				this.next();
			}, 1000);
		},
		next() {
            if(this.currentIndex < (this.slides.length - 1)){
                this.currentIndex += 1;
            }
		},
		prev() {
			this.currentIndex -= 1;
		}
    },
    mounted: function() {
		this.startSlide();

	}
}
</script>

<style scoped>
.publi {
    width: 100%;
}

.publi .top .icon {
    background-color: #0D93C6;
    background-position: 5px 5px;
}


.publi .content ul {
    float: left;
    width: 100%;
    height: 400px;
    border-radius: 12px;
}

.publi .content ul li {
    position: absolute;
    top: 0;
    left: 0;
}

.publi .content ul li,
.publi .content ul li > div {
    float: left;
    width: 100%;
    height: 100%;
}

.publi .content ul li .img {
    position: relative;
    float: left;
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    border-radius: 12px;
}

.publi .content ul li .data {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    padding: 10px 10px 18px 10px;
    font-size: 14px;
    line-height: 20px;
    color: #fff;
    background: rgb(0,0,0);
    background: linear-gradient(0deg, rgba(0,0,0,.7) 0%, rgba(255,255,255,0) 100%);
    border-radius: 0 0 12px 12px;
}

.publi .content ul li .data span {
    float: left;
    width: 100%;
    height: auto;
    text-align: center;
    text-shadow: 0 3px 6px rgba(0,0,0,.44);
}
.publi .content ul li .data span:first-of-type {font-weight: 500;}

.publi .content .bolls {
    position: relative;
    top: -2px;
    left: 0;
    width: 100%;
    height: 14px;
    font-size: 10px;
    display: flex;
    justify-content: center;
    color: #fff;
    z-index: 9;
}

.publi .content .bolls .boll {opacity: .6; margin: 0 3px 0 3px;}
.publi .content .bolls .boll.active {opacity: 1;}
</style>