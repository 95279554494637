<template>
	<div v-if="this.maintenance == 1">
		<div style="margin-top: 80px; display: flex;justify-content: center; ">
			Em manutenção.<br>
			<img src="https://cdn.discordapp.com/attachments/987038202407837719/1028455911867564093/unknown.png" style="margin-top: 10px">
			<br>
			<audio id="audio" autoplay="" controls src="https://painel.dedicado.stream/8008/stream" type="audio/mpeg"></audio>
		</div>
	</div>
	<div v-else>
		<HeaderItem />
		<!-- @searchH="setSearch($event)" -->
		<div class="container">
			<aside>
				<PubliItem/>
				<BadgesFree/>
				<ItemRankCom />
				<BadgesNewsItem/>
				<RankItem/>
			</aside>
			<div v-if="this.onSearch != ''" class="main">
				<SearchItem :search="this.onSearch" @clear="this.onSearch = ''"/>
			</div>
			<div v-else class="main">
				<router-view :key="this.$route.fullPath"/>
			</div>
		</div>
		<FooterItem :logof="this.logo_footer" />
		<Transition name="slide-fade">
			<Alerta v-if="$store.state.textA != ''" :text="$store.state.textA"/>
		</Transition>
	</div>
</template>

<script>
import HeaderItem from './components/header.vue'
import FooterItem from './components/footer.vue'
import PubliItem from './components/home/publi.vue'
import BadgesFree from './components/home/badges-free.vue'
import BadgesNewsItem from './components/home/badges-new.vue'
import RankItem from './components/home/ranking.vue'
import SearchItem from './views/search.vue'
import ItemRankCom from './components/home/rank-comment.vue'

import axios from 'axios'
import { base } from '../var.js'
import Alerta from './components/usefulness/alert.vue'

export default {
  components: {
	HeaderItem,
	FooterItem,
	PubliItem,
	BadgesFree,
	BadgesNewsItem,
	RankItem,
	SearchItem,
	ItemRankCom,
	Alerta
  },
  emits: ['searchH'],
  data(){
	return {
		color1: '',
		color2: '',
		logo: '',
		header: '',
		logo_footer: '',
		favicon: '',
		root: document.documentElement,
		onSearch: '',
		maintenance: false,
		on: false
	}
  },
  methods: {
	async getInit(){
		const req = await axios.get(base+'getItens/init')
		
		this.locutorOfc = req.data.radioUser
		this.color1 =  req.data.color1
		this.color2 = req.data.color2
		
		this.$store.commit('storeSite', {
			header: req.data.header,
			logo: req.data.logo,
			logo_footer: req.data.logo_footer,
			favicon: req.data.favicon,
			clouds:  new Boolean(req.data.clouds).valueOf()
		})

		if(this.$store.state.dev == false){
			this.maintenance = req.data.maintenance
		}

		this.root.style.setProperty("--color1", this.color1);
		this.root.style.setProperty("--color2", this.color2);

		var link = document.querySelector("link[rel*='icon']") || document.createElement("link");
		link.type = "image/x-icon";
		link.rel = "shortcut icon";
		link.href = req.data.favicon;
		document.getElementsByTagName("head")[0].appendChild(link);

		const log = await axios.get(base+'page/logged')
		if(log.data.logged != false){
			this.$store.state.logged = log.data.logged
			this.$store.commit('storeUser', {user: log.data, logged: log.data.logged})
		}
	},
	async setSearch(e){
		this.onSearch = e;
	}
  },
  mounted(){
	this.getInit();
	window.scrollTo(0, 0);
	document.title = 'Rádio Habblet • Um novo jeito de fazer fã-site!';
	this.$store.dispatch('player');
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {font-family: "Montserrat", sans-serif;background-color: #EFF2F5;}
* {padding: 0;margin: 0;box-sizing: border-box;}

button,textarea,li,ul,select,input,a {border:0;list-style: none;background-color: transparent;outline: none;color: inherit;text-decoration: none;font-family: 'Montserrat', sans-serif;}
button[disabled] {opacity: .6;}

#app {
	position: relative;
	width: 100%;
	height: auto;
	overflow: hidden;
}

.nobr {white-space: nowrap;text-overflow: ellipsis;overflow: hidden;}

:root {
  --width: 1200px;
  --color1: #009ECC;
}

.center {
  position: relative;
  width: var(--width);
  margin: 0 auto;
}

.container {
	position: relative;
	width: var(--width);
	height: auto;
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
}

.container aside{
	width: 30%;
}

.container .item {
	position: relative;
	float: left;
	margin-top: 30px;
	width: calc(100% - 20px);
}
.container > .item,
.container .main {width: 70%;}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

.item .top {
	position: relative;
	width: 100%;
	height: 40px;
	display: flex;
	justify-content: space-between;
}

.item .top .icon,
.page .top .icon {
	float: left;
	width: 34px;
	height: 34px;
	border-radius: 30px;
	color: #fff;
	font-size: 15px;
	line-height: 34px;
	text-align: center;
	background-image: url('./assets/icons.png');
	background-repeat: no-repeat;
	box-shadow: 0 8px 15px rgba(100,100,100,.35);
	user-select: none;
}

.item .top .title,
.page .top .title {
	float: left;
	width: auto;
	height: auto;
	user-select: none;
	font-size: 16px;
	color: #112535;
	margin: 8px 0 0 8px;
	user-select: none;
}

.item .top .title span,
.page .top .title span {font-weight: 800;}

.item .top .actions	 {
	display: flex;
	justify-content: space-between;
	user-select: none;
}

.item .top .actions button {
	float: left;
	width: 34px;
	height: 34px;
	background-color: #D7DDE4;
	border-radius: 30px;
	font-size: 16px;
	color: #9EA6AF;
	margin-left: 4px;
	cursor: pointer;
}

.item .top .actions input {
	float: left;
	width: 140px;
	height: 34px;
	border-radius: 23px;
	background-color: #E7EBF0;
	padding: 0 10px;
	color: #9EA6AF;
	font-size: 14px;
	margin-left: 6px;
}
.item .top .actions input::placeholder {
	color: #9EA6AF;
	font-size: 14px;
}
.item .top .actions form button {background-color: #2FC145;color: #fff;}

.item .content {
	position: relative;
	float: left;
	width: 100%;
	margin-top: 10px;
}

.page {
	position: relative;
	float: left;
	width: 100%;
	height: auto;
	margin-top: 30px;
}

.page .content {
	float: left;
	width: 100%;
	height: auto;
	padding: 8px;
	border-radius: 10px;
	background-color: #fff;
	margin-top: 16px;
	box-shadow: 0px -2px 6px rgba(0,0,0,.05);
	font-size: 13px;
}

.comments .top {
	float: left;
	width: 100%;
	height: 40px;
	margin-top: 30px;
}
.comments .content {margin-top: 10px;padding: 10px;}

.comments .top .title {
	float: left;
	width: 100%;
	height: 22px;
	margin: 10px 0 0 10px;
	font-size: 18px;
	font-weight: 800;
	color: #112535;
}

.comments .box {
	float: left;
	width: 100%;
	height: auto;
	margin-top: 8px;
}

.comments .box .avatar {
	float: left;
	width: 50px;
	height: 50px;
	border-radius: 50px;
	border: 1px solid rgba(0,0,0,.1);
	background-position: center -33px;
	background-repeat: no-repeat;
}

.comments .box .text {
	float: left;
	width: calc(100% - 60px);
	height: auto;
	border-radius: 10px;
	background-color: #EFF2F5;
	color: #050505;
	font-size: 14px;
	margin-left: 10px;
	padding: 10px;
}
.comments .box textarea.text {height: 100px;}
.comments .box textarea.text::placeholder {color: #050505;font-size: 14px;}

.comments .box button {
	float: right;
	width: auto;
	height: 36px;
	border-radius: 10px;
	color: #fff;
	font-size: 14px;
	font-weight: 600;
	background-color: #2FC145;
	padding: 0 14px;
	margin-top: 8px;
}

.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0,.4);
	z-index: 9999;
}

.modal .empty {
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: 0;
}

.modal .box {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 400px;
	height: auto;
	background-color: #fff;
	border-radius: 12px;
	box-shadow: 0 1px 6px rgba(0,0,0,.15);
	transform: translate(-50%,-50%);
	z-index: 1;
}

.modal .top {
	position: relative;
	float: left;
	width: 100%;
	height: 70px;
	border-bottom: 2px solid rgba(0,0,0,.1);
	padding: 10px;
	user-select: none;
	line-height: 20px;
}

.modal .top .title {
	float: left;
	width: 70%;
	height: 24px;
	font-size: 20px;
	font-weight: 800;
	color: #050505;
	margin-top: 4px;
}

.modal .top .subTitle {
	float: left;
	width: 70%;
	height: 20px;
	font-size: 14px;
}

.modal .top .close {
	position: absolute;
	top: 10px;
	right: 10px;
	width: 20px;
	height: 20px;
	color: #A7A7A7;
	font-size: 14px;
	text-align: center;
	line-height: 20px;
	user-select: none;
}

.modal .content {
	position: relative;
	float: left;
	width: 100%;
	height: auto;
	padding: 10px;
	box-shadow: none;
}

.modal .content input,
.modal .content textarea {
	float: left;
	width: 100%;
	/* max-width: 100%;
	min-width: 100%; */
	height: 40px;
	padding: 10px;
	border-radius: 12px;
	background-color: rgba(0,0,0,.1);
	margin-top: 6px;
	font-size: 14px;color: #444;
}
.modal .content input::placeholder {font-size: 14px;color: #444;}

.modal .content button {
	float: left;
	width: auto;
	height: 36px;
	background-color: #2EC044;
	color: #fff;
	font-size: 14px;
	font-weight: 500;
	border-radius: 12px;
	padding: 0 30px;
	margin: 14px 0 0 50%;
	transform: translateX(-50%);
	cursor: pointer;
}

@media only screen and (max-width: 500px) {
	.center {width: 100%;}

	.container, .container aside {width: 100%;display: block;}
	.container {display: flex; flex-direction: column-reverse;}

	.container .item {width: 100%;padding: 0 10px;}

	.container > .item, .container .main {width: 100%;}

	.news .content {flex-flow: column;}
	.news .content .box {width: calc(100% - 16px) !important;}

	.headerMain { background-size: cover !important;}

	.event,
	.podcast {
		width: calc(100% - 20px) !important;
		margin-left: 10px;
	}

	.galeria, .player  {border-radius: 0 !important;}

	.badges .content .box,
	.last .content .box {width: calc(25% - 10px) !important;}

	.container .item.badges {padding: 6px 10px;height: auto;}

	.shop .content .box  {width: 100% !important;margin: 8px 0 0 0 !important;}

	footer {padding-bottom: 40px;}
	footer .nav {justify-content: space-evenly !important;}
	footer .nav .btn { padding: 20px 0 !important;}

	.page {padding: 10px;}

	.path {height: 40px !important;}

	#iframe {width: 100%;height: auto;margin-top: 6px;border-radius: 4px;}

	.commentFeed::before {left: 8px;}

	.val .val-cat {display: flex; flex-flow: wrap;}

	.val .val-cat .btn {margin-top: 6px !important;}
	.val .content-val .mob {width: calc(50% - 10px) !important;}

	.team .content > .box {width: calc(50% - 10px) !important;}

	.hours .content .box {width: calc(100% / 2 - 10px) !important;}

	.modal {
		width: 100%;
	}

}

.rh-plus {
  animation: colorRotate 5s linear 0s infinite;
}

@keyframes colorRotate {
  0% {
    color: #2323e6;
  }
  25% {
    color: #f02020;
  }
  50% {
    color: #14db14;
  }
  75% {
    color: #ff3399;
  }
  100% {
    color: #2323e6;
  }
}




</style>
